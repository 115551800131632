// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll

jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t=t/d-1)*t*t*t - 1) + b;
};

jQuery(document).ready(function(){
  jQuery('a[href*="#"]').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var $target = jQuery(this.hash);
      $target = $target.length && $target || jQuery('[name=' + this.hash.slice(1) +']');
      if ($target.length) {
        var targetOffset = $target.offset().top;
        jQuery('html,body').animate({ scrollTop: targetOffset }, 1200, 'quart');
        return false;
      }
    }
  });

});

// slide btn

$(function(){
  $(".slide-btn").each(function(index,element){
      $(element).click(function(){
        $(this).toggleClass('on');
        $(element).next(".slide-panel").slideToggle(300);
        });
      });

  // ハッシュがあるときはオープン
  if(window.location.hash.length>0){
    $(window.location.hash).toggleClass('on').next(".slide-panel").toggle();
  }
});

// phone number
$(function(){
  var ua = navigator.userAgent;
  if (ua.indexOf('iPhone') < 0 && ua.indexOf('Android') < 0) {
    $('.telhref').wrap('<span class="telbox"></span>');
    $('.telhref').contents().unwrap();
  }
});

//fadein

$(window).scroll(function (){
  $('.fadeIn').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight - 30){
        $(this).addClass('scrollIn');
    }
  });
});


//pagetop & fixheader

$(window).scroll(function(){
  var ptop = $('#ptop');
  var header = $('#fixheader');
  var scroll = $(window).scrollTop();
  if(scroll > 600) {
    ptop.addClass('on');
    header.addClass('on');
  } else {
    ptop.removeClass('on');
    header.removeClass('on');
  }
});


//slick - index
$(document).ready(function(){

  $('.main-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    fade: true
  });

});